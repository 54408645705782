<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron materiales a instalar/facturar"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="subsis.idsubsis"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <lparte-trabajo-subsis-list-item
          :item="slotProps.item"
        />
      </template>
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
      />
    </b10-list>
    <lparte-trabajo-subsis-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './LparteTrabajoSubsisListData'
import LparteTrabajoSubsisListItem from './components/LparteTrabajoSubsisListItem'
import LparteTrabajoSubsisListLegend from './components/LparteTrabajoSubsisListLegend'
import { get } from 'vuex-pathify'
import { NextRoute } from '@/router'
import { PARTE_TRABAJO } from '@/utils/consts'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'

const pageStoreName = 'pagesOfflineLparteTrabajoSubsisList'

export default {
  components: {
    LparteTrabajoSubsisListItem, LparteTrabajoSubsisListLegend, VolverParteChecklistMenuItems,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        addMaterialNuevo: {
          title: 'Material a instalar nuevo',
          visible: true,
          icon: 'plus'
        },
        addMaterialOT: {
          title: 'Material a instalar desde la OT',
          visible: true,
          icon: 'plus'
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false
      },
      parteTrabajo: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    estadoPartePendiente () {
      return (
        this.parteTrabajo &&
        this.parteTrabajo.parte_trabajo &&
        this.parteTrabajo.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = `Material instalar/facturar parte Nº${this.routeParams.idparte_trabajo}`
      this.parteTrabajo = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
      this.toolbarOptions.addMaterialNuevo.visible = this.estadoPartePendiente
      this.toolbarOptions.addMaterialOT.visible = this.estadoPartePendiente
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMaterialInstalar(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idparte_trabajo
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectMaterialInstalarRows(this, dirty.modified, this.routeParams.idparte_trabajo),
          dirty,
          'subsis.idsubsis'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idsubsis: item.data.subsis.idsubsis,
        }
      })
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.addMaterialNuevo) {
        this.$appRouter.push({
          name: 'offline__articulo-list',
          params: {
            nextRoute: NextRoute(
              'offline__lparte-trabajo-add',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idarticulo: null,
              },
              {},
              true,
            ),
          },
          query: {
            idttarifa: this.parteTrabajo.orden_trabajo.idttarifa,
            idbanco_precio: this.parteTrabajo.orden_trabajo.idbanco_precio,
          }
        })
      } else if (option === this.toolbarOptions.addMaterialOT) {
        this.$appRouter.push({
          name: 'offline__lorden-trabajo-list',
          params: {
            idorden_trabajo: this.parteTrabajo.parte_trabajo.idorden_trabajo,
            nextRoute: NextRoute(
              'offline__lparte-trabajo-lorden-trabajo-add',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idlorden_trabajo: null,
              },
              {
                idsubsis: this.routeParams.idsubsis,
              },
              true,
            ),
          },
          query: {
            soloPendienteInstalar: true,
          },
        })
      }
    },
  }
}
</script>
