import { likeRegExp } from '@/offline/database'

export default {
  _selectMaterialInstalarBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        tables.lparte_trabajo.idparte_trabajo,
        tables.subsis.codigo,
        tables.subsis.descripcion,
        tables.subsis.idsubsis,
        tables.subsis.orden,
        Vue.$offline.db.fn.sum(
          tables.lparte_trabajo.unidades
        ).as('cant_materiales')
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.subsis,
        tables.lparte_trabajo.idsubsis.eq(tables.subsis.idsubsis)
      )
      .groupBy(
        tables.lparte_trabajo.idparte_trabajo,
        tables.subsis.codigo,
        tables.subsis.descripcion,
        tables.subsis.idsubsis,
        tables.subsis.orden
      )
  },
  async selectMaterialInstalar (Vue, filter, search, sorter, page, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.subsis.codigo.match(reQ),
          tables.subsis.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
        tables.lparte_trabajo.estado.gt(0),
        ...whereSearch,
        ...whereFilter,
      )
    )
    return [
      await this._selectMaterialInstalarBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .orderBy(tables.subsis.orden)
        .orderBy(tables.subsis.descripcion)
        .orderBy(tables.subsis.idsubsis)
        .exec(),
      []
    ]
  },
  async selectMaterialInstalarRows (Vue, pks, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return await this._selectMaterialInstalarBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.subsis.idsubsis.in(pks),
        )
      )
      .exec()
  },
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    )[0]
  },
}
